import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/monitory-point",
    method: "Get",
    params: params,
  });
}

// 查询全部监控点
export function queryAll(params) {
  return request({
    url: "/monitory-point/all",
    method: "Get",
    params: params,
  });
}

// 增加
export function addMonitoryPoint(requestBody) {
  return request({
    url: "/monitory-point",
    method: "Post",
    data: requestBody,
  });
}

// 编辑
export function editMonitoryPoint(requestBody) {
  return request({
    url: "/monitory-point",
    method: "Patch",
    data: requestBody,
  });
}

// 删除
export function deleteMonitoryPoint(params) {
  return request({
    url: "/monitory-point",
    method: "Delete",
    params: params,
  });
}

// 获取MonitoryPoint option
export function getMonitoryPointOptionsByCompanyName(params) {
  return request({
    url: "/monitory-point/option",
    method: "Get",
    params: params,
  });
}

// 获取绑定污染物列表
export function getBindingList(params) {
  return request({
    url: "/monitory-point/binding",
    method: "Get",
    params: params,
  });
}

// 绑定污染物
export function binding(requestBody) {
  return request({
    url: "/monitory-point/binding",
    method: "Post",
    data: requestBody,
  });
}

// 获取已绑定的污染物
export function getBinded(params) {
  return request({
    url: "/monitory-point/binded",
    method: "Get",
    params: params,
  });
}

// 设置污染物上下限
export function setUpperLower(params) {
  return request({
    url: "/monitory-point/upper-lower",
    method: "Post",
    params: params,
  });
}
