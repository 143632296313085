<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> 信息管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>监控点信息</el-breadcrumb-item>
        <el-breadcrumb-item>绑定污染物</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-button @click="handleGoBack" plain icon="el-icon-back" size="mini">
          返回上一页</el-button
        >
        <el-button
          @click="handleConfirm"
          plain
          icon="el-icon-download"
          size="mini"
          type="danger"
        >
          保存
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="6">
          <el-input
            clearable
            size="mini"
            v-model="pollutionCodeInput"
            placeholder="污染物编号"
          >
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column type="index" :index="1"> </el-table-column>
        <el-table-column prop="pollutionName" label="污染物名称" sortable>
        </el-table-column>
        <el-table-column prop="pollutionCode" label="污染物编号" sortable>
        </el-table-column>
        <el-table-column prop="pollutionUnit" label="污染物单位">
        </el-table-column>
        <el-table-column prop="pollutionType" label="污染物类型" sortable>
        </el-table-column>
        <el-table-column prop="lowerLimit" label="报警下限">
          <template #default="scope">
            <span v-if="scope.row.lowerLimit === null">--</span>
            <span v-else>{{ scope.row.lowerLimit }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="upperLimit" label="报警上限">
          <template #default="scope">
            <span v-if="scope.row.upperLimit === null">--</span>
            <span v-else>{{ scope.row.upperLimit }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import router from "@/router";
import { binding, getBindingList } from "@/api/monitory-point";

export default {
  name: "MonitoryPointBinding",
  data() {
    return {
      pollutionCodeInput: "",
      tableData: [],
      multipleSelection: [],
    };
  },
  created() {
    this.getBindingList();
  },
  methods: {
    handleClear() {
      this.pollutionCodeInput = "";
    },
    handleSearch() {
      const find = this.$_.find(this.tableData, {
        pollutionCode: this.pollutionCodeInput,
      });
      if (find !== undefined) {
        // 1.找到后删除
        this.$_.pull(this.tableData, find);
        // 2.插入第一个
        this.tableData.unshift(find);
        // 3.调用搜索框清除函数
        this.handleClear();
      } else {
        ElMessage.warning({
          message: "搜索污染物不存在",
          type: "warning",
        });
      }
    },
    handleSelectionChange(val) {
      console.log(JSON.stringify(val));
      this.multipleSelection = val;
    },
    handleConfirm() {
      console.log("多选选中:", JSON.stringify(this.multipleSelection));
      const pollutionCodes = this.$_.map(
        this.multipleSelection,
        "pollutionCode"
      );
      console.log(pollutionCodes);
      const id = this.$route.query.id;
      // 请求参数
      const requestBody = {
        id: id,
        pollutionCodes: pollutionCodes,
      };
      // 发送请求
      binding(requestBody).then(() => {
        this.getBindingList();
      });
    },
    handleGoBack() {
      router.go(-1);
      this.$store.commit("closeCurrentTag", {
        $router: this.$router,
        $route: this.$route,
      });
    },
    // 获取列表, 多选框选中绑定的污染物
    getBindingList() {
      const id = this.$route.query.id;
      const params = { id: id };
      getBindingList(params).then((result) => {
        this.tableData = result.data;
        // 设置选中
        this.$nextTick(() => {
          this.tableData.forEach((row) => {
            if (row.selection === true) {
              this.$refs.multipleTable.toggleRowSelection(row, true);
            }
          });
        });
      });
    },
  },
};
</script>

<style scoped></style>
